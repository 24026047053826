@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

a {
  text-decoration: none;
  font-weight: 600;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

.SnackbarContent-root {
  font-size: 16px !important;
  font-weight: 500 !important;
  /*font-family: neue-haas-grotesk-display;*/
}

.SnackbarItem-variantSuccess {
  background-color: #4fc1be !important;
}
.SnackbarItem-variantError {
  background-color: #ca7a85 !important;
}
.SnackbarItem-variantInfo {
  background-color: #29abe2 !important;
}
.SnackbarItem-variantWarning {
  background-color: #fc8a4d !important;
}

.PrivatePickersYear-yearButton.Mui-selected {
  color: white !important;
}

/* Scrollbar classes for MaterialUI Box */
.MuiBox-root::-webkit-scrollbar {
  width: 0.3em;
}

.MuiBox-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.MuiBox-root::-webkit-scrollbar-thumb {
  background-color: rgb(226, 226, 226);
}

/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
  fill: #8cc665;
}

/*
 * Github color scale
 */

.react-calendar-heatmap .color-github-0 {
  fill: #eeeeee;
}
.react-calendar-heatmap .color-github-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-github-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-github-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-github-4 {
  fill: #1e6823;
}

/*
 * Gitlab color scale
 */

.react-calendar-heatmap .color-gitlab-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-gitlab-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-gitlab-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-gitlab-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-gitlab-4 {
  fill: #254e77;
}

/*
 * Motryx color scale
 */

.react-calendar-heatmap .color-motryx-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-motryx-1 {
  fill: #4fc1be;
}

