@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

a {
  text-decoration: none;
  font-weight: 600;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

.SnackbarContent-root {
  font-size: 16px !important;
  font-weight: 500 !important;
  /*font-family: neue-haas-grotesk-display;*/
}

.SnackbarItem-variantSuccess {
  background-color: #4fc1be !important;
}
.SnackbarItem-variantError {
  background-color: #ca7a85 !important;
}
.SnackbarItem-variantInfo {
  background-color: #29abe2 !important;
}
.SnackbarItem-variantWarning {
  background-color: #fc8a4d !important;
}

.PrivatePickersYear-yearButton.Mui-selected {
  color: white !important;
}

/* Scrollbar classes for MaterialUI Box */
.MuiBox-root::-webkit-scrollbar {
  width: 0.3em;
}

.MuiBox-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.MuiBox-root::-webkit-scrollbar-thumb {
  background-color: rgb(226, 226, 226);
}
